import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from 'contexts/global/globalContext';
/* eslint-disable */
export default function DropMenu({ title, children, className, activeStates, defaultParentLink }) {
  const [submenu, setSubmenu] = useState(false);
  const [activeState, setActiveState] = useState(false);
  const globalContext = useContext(GlobalContext);
  const handleMouseEnter = () => {
    if (!globalContext.isMobile) {
      setSubmenu(true)
    }
  };

  const handleMouseLeave = () => {
    if (!globalContext.isMobile) {
      setSubmenu(false)
    }
  }

  useEffect(() => {
    const currentURL = document.URL;
    if (globalContext.isMobile) {
      setSubmenu(true)
    }
    else {
      setSubmenu(false)
    }
    if (activeStates) {
      activeStates.map(iStr => {
        currentURL.includes(iStr) && setActiveState(true)
      })
    }

  }, [globalContext.isMobile])

  return (
    <div className={`drop-menu ${className}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <a href={defaultParentLink || "#"} className={activeState ? 'active' : ''}><span dangerouslySetInnerHTML={{ __html: title }} /></a>
      <div className={`sub-menu-wrapper ${submenu ? 'show' : 'hide'}`}>
        <span className="sub-menu-arrow">▲</span>
        <div className="sub-menu">
          {children}
        </div>
      </div>
    </div>
  )
}
