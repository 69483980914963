import React, { useState } from 'react'
/* eslint-disable */
export default function Expandable({ className, linkTitle, children, type }) {
  const [expandState, setExpandState] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setExpandState(!expandState);
  }

  return (
    <div className={`expandable ${className}`}>
      <a href="#" className="expandable-link" onClick={handleClick}
      >
        {linkTitle}
        <span className={`arrow ${expandState && 'active'}`}><span></span><span></span></span>
      </a>
      <div className={`expandable-content ${expandState && "active"}`}>
        {children}
      </div>
    </div>
  )
}
