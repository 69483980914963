import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { useCookies } from 'react-cookie';
import Modal from 'react-awesome-modal';
import GlobalContext from 'contexts/global/globalContext';

/* eslint-disable */

export default function HcpLinkModal(HcpURL) {
  const globalContext = useContext(GlobalContext);
  const [cookies, setCookie] = useCookies(['cookie-name']);
  const handleContinue = (e) => {
    e.preventDefault();
    globalContext.setHcpModal(false);
    setCookie('hcpVisited', true);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    globalContext.setHcpModal(false);
    document.referrer !== ''
      ? window.history.back()
      : navigate('/');
  }

  return (
    <Modal visible={globalContext.hcpModalState} effect="fadeInUp">
      <div id="modal-hcp-interstitial" className="modal-container">
        <h2>Intended for U.S. Residents Only</h2>
        <div className="modal-contents">
          <p>Welcome to AVEEDUSA.com</p>
          <p>Are you a patient or a healthcare professional?</p>
        </div>
        <div className="modal-actions">
          <a href="#" onClick={handleCancel} className="aveed-button green">I am a patient</a>
          <a href="#" onClick={handleContinue} className="aveed-button green">I am a healthcare professional</a>
        </div>
        <a href="#" onClick={handleCancel} className="aveed-button btn-close">✕</a>
      </div>
    </Modal>
  )
}
