import React from "react";
import PropTypes from "prop-types";
import LayoutWrapper from "./LayoutWrapper";
import { HeaderPatient } from "../header";
import { FooterPatient } from "../footer";
import Body from "../body";

import "scss/patient.scss";

//NOTE:
//GlobalContext may not be available in this component.
//Check <Body/> component

export default function LayoutPatient({ pageName, pageClassName, children }) {
  return (
    <LayoutWrapper indication="patient">
      <div className={pageClassName}>
        <HeaderPatient />
        <Body type="patient" pageName={pageName || ""}>
          <section className="body-content">{children}</section>
        </Body>
        <FooterPatient />
      </div>
    </LayoutWrapper>
  );
}

LayoutPatient.propTypes = {
  children: PropTypes.node.isRequired,
  pageClassName: PropTypes.string,
};
