import React, { useContext } from "react";
import GlobalContext from 'contexts/global/globalContext';
import PropTypes from "prop-types";
import { Link } from "gatsby";
import NavWrappper from "./NavWrapper";
import DropMenu from "./DropMenu";

export default function NavHcp({ className }) {
  const globalContext = useContext(GlobalContext);
  const handleClick = () => {
    globalContext.isMobile && globalContext.setHamburgerState(false);
  }
  return (
    <NavWrappper>
      <hr className="nav-separator-mobile" />
      <DropMenu
        title="Clinical Data"
        className="nav-clinical-data"
        defaultParentLink="/hcp/clinical-data/?scrollto=elevate_and_control"
        activeStates={[
          "clinical-data"
        ]}
      >
        <a
          href="/hcp/clinical-data/?scrollto=elevate_and_control"
          onClick={handleClick}
        >
          <span>Elevate &amp; Control</span>
        </a>
        <a
          href="/hcp/clinical-data/?scrollto=efficacy"
          onClick={handleClick}
        >
          <span>Efficacy</span>
        </a>
        <a href="/hcp/clinical-data/?scrollto=testosterone_levels"
          onClick={handleClick}>
          <span>Testosterone Levels</span>
        </a>
      </DropMenu>
      <span className="nav-separator-desktop">|</span>
      <hr className="nav-separator-mobile" />
      <Link
        to="/hcp/clinical-safety-profile"
        activeClassName="active"
      >
        <span>Clinical Safety Profile</span>
      </Link>
      <span className="nav-separator-desktop">|</span>
      <hr className="nav-separator-mobile" />
      <Link
        to="/hcp/hypogonadism-provider-enrollment/"
        activeClassName="active"
      >
        <span>AVEED<sup>&reg;</sup> REMS</span>
      </Link>
      <span className="nav-separator-desktop">|</span>
      <hr className="nav-separator-mobile" />
      <DropMenu
        title="Get AVEED<sup>&reg;</sup>"
        className="nav-get-aveed"
        defaultParentLink="/hcp/get-aveed/?scrollto=access_and_reimbursement"
        activeStates={[
          "get-aveed"
        ]}
      >
        <a
          href="/hcp/get-aveed/?scrollto=access_and_reimbursement"
          onClick={handleClick}
        >
          <span>Access &amp; Reimbursement</span>
        </a>
        <a
          href="/hcp/get-aveed/?scrollto=order_via_specialty_pharmacy"
          onClick={handleClick}
        >
          <span>Order via Specialty Pharmacy</span>
        </a>
        <a href="/hcp/get-aveed/?scrollto=order_via_buy_and_bill"
          onClick={handleClick}>
          <span>Order via Buy-and-Bill</span>
        </a>
        <a
          href="/hcp/get-aveed/?scrollto=billing_codes"
          onClick={handleClick}
        >
          <span>Billing Codes</span>
        </a>
      </DropMenu>
      <span className="nav-separator-desktop">|</span>
      <hr className="nav-separator-mobile" />
      <Link
        to="/hcp/dosing-and-administration/"
        activeClassName="active"
      >
        <span>Dosing &amp; Administration</span>
      </Link>
      <span className="nav-separator-desktop">|</span>
      <hr className="nav-separator-mobile" />
      <Link
        to="/hcp/paying-for-aveed/"
        activeClassName="active"
      >
        <span>Paying for AVEED<sup>&reg;</sup></span>
      </Link>
      <span className="nav-separator-desktop">|</span>
      <hr className="nav-separator-mobile" />
      <Link
        to="/hcp/resources/"
        activeClassName="active"
      >
        <span>Resources</span>
      </Link>
      <hr className="nav-separator-mobile" />
    </NavWrappper>
  );
}

NavHcp.propTypes = {
  className: PropTypes.string
};
