import React, { useState, useContext, useEffect } from 'react'
import StickyFooter from 'react-sticky-footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { getUrlParameter } from 'libs/util.helper';
import GlobalContext from 'contexts/global/globalContext';

export default function ISIWrapper({ children }) {
  const globalContext = useContext(GlobalContext);
  const [footerHeight, setFooterHeight] = useState(0);
  const [sticky, setSticky] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    setFooterHeight(globalContext.footerHeight);
    if (globalContext.appConfigs.isi.type === "sticky") {
      if (!globalContext.isMobile) {
        setSticky(true);
      }
      else {
        if (globalContext.hamburgerState) {
          setSticky(false)
        }
        else {
          setSticky(true)
        }
      }
    } else {
      setSticky(false);
    }

    //Adding magicshot feature
    getUrlParameter('magicshot') && setSticky(false)

    //eslint-disable-next-line
  }, [globalContext.footerHeight, globalContext.hamburgerState, globalContext.isMobile])

  return (
    sticky ?
      <StickyFooter
        stickyStyles={globalContext.appConfigs.isi.stickyStyles}
        bottomThreshold={
          footerHeight - globalContext.appConfigs.isi.stickyStyles.height
        }
        onFooterStateChange={
          result => {
            setExpanded(result)
          }
        }
      >
        <div id="aveed-isi" className="dfa-container gutter-left gutter-right sticky-isi-container">
          {
            !isExpanded &&
            <AnchorLink href="#aveed-isi" className="btn-expand-isi" offset={globalContext.headerHeight}><span className="btn-expand-text">See More</span><span className="up-arrow-symbol">▲</span></AnchorLink>
          }
          {children}
        </div>
      </StickyFooter>
      :
      <div className="dfa-container  gutter-left gutter-right">
        {children}
      </div>
  )
}
