import React from 'react'

export default function index({ children, colorWare, numero, title, className }) {
  return (
    <div className={`numero-block ${colorWare} ${className}`}>
      <div className="numero"><span>{numero}</span></div>
      <div className="text-block">
        {
          title &&
          <span className="block-title">{title}</span>
        }
        {
          children &&
          <span className="block-text">{children}</span>
        }
      </div>
    </div>
  )
}
