import React, { useLayoutEffect, useRef, useContext } from "react"
import PropTypes from "prop-types"
import FooterWrapper from "./FooterWrapper"
import { ISIPatient } from "../isi"
import { useResizeObserver } from "hooks"
import GlobalContext from "contexts/global/globalContext"

export default function FooterPatient({ className, children }) {

  const ref = useRef(null);
  const elementSize = useResizeObserver(ref);
  const globalContext = useContext(GlobalContext);

  useLayoutEffect(() => {
    globalContext.setFooterHeight(elementSize.height)
    //eslint-disable-next-line
  }, [elementSize.height])

  return (
    <div ref={ref}>
      <ISIPatient />
      <FooterWrapper className={className} jobCode={globalContext.appConfigs.patientJobCode}>
        {children}
      </FooterWrapper>
    </div>
  )
}

FooterPatient.propTypes = {
  className: PropTypes.string
}
