import React from "react";
import PropTypes from "prop-types";
import LayoutWrapper from "./LayoutWrapper";
import { HeaderHcp } from "../header";
import { FooterHcp } from "../footer";
import Body from "../body";

import "scss/hcp.scss";

//NOTE:
//GlobalContext may not be available in this component.
//Check <Body/> component
export default function LayoutHcp({
  pageName,
  pageClassName,
  children,
  references,
}) {
  return (
    <LayoutWrapper indication="hcp">
      <div className={pageClassName}>
        <HeaderHcp />
        <Body type="hcp" refs={references} pageName={pageName || ""}>
          <section className="body-content">{children}</section>
        </Body>
        <FooterHcp />
      </div>
    </LayoutWrapper>
  );
}

LayoutHcp.propTypes = {
  children: PropTypes.node.isRequired,
  pageClassName: PropTypes.string,
};
