import React, { useContext } from "react"
import PropTypes from "prop-types"
import UtilNav from "../utility-nav"
import IndicationBar from "../indication-bar"
import GlobalContext from "contexts/global/globalContext"
/* eslint-disable */
export default function NavWrapper({ children }) {
  const globalContext = useContext(GlobalContext)
  const rightValue = () => {
    if (!globalContext.isMobile) {
      return 0
    } else {
      if (globalContext.hamburgerState) {
        return 0
      } else {
        return "-100vw"
      }
    }
  }
  const paddingTopValue = () => {
    let pt = 0;
    if (globalContext.isMobile) {
      pt = globalContext.headerHeight;
    }
    return pt;
  }
  return (
    <div className="nav-wrapper" style={{
      paddingTop: paddingTopValue()
    }}>
      <div
        className={`navbar-contents ${globalContext.hamburgerState ? "active" : ""}`}
        style={{
          right: rightValue()
        }}
      >
        <IndicationBar />
        <nav>
          {children}
        </nav>
        <UtilNav className="mobile-utility-nav" />
      </div>
      <div
        className={`hamburger ${globalContext.hamburgerState ? "active" : ""}`}
        onClick={() => {
          globalContext.setHamburgerState(!globalContext.hamburgerState)
        }}
      >
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
    </div>
  )
}

NavWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
