import React, { useLayoutEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useResizeObserver } from 'hooks';
import GlobalContext from 'contexts/global/globalContext';

export default function HeaderWrapper({ children }) {
  const ref = useRef(null);
  const elementSize = useResizeObserver(ref);
  const globalContext = useContext(GlobalContext);

  useLayoutEffect(() => {
    globalContext.setHeaderHeight(elementSize.height)
    //eslint-disable-next-line
  }, [elementSize.height])

  return (
    <div className={`header-wrapper ${globalContext.appConfigs.headerType === 'fixed' ? 'fixed-header' : ''}`} ref={ref}>
      {children}
    </div>
  )
}

HeaderWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
