import React, { useContext } from "react";
import PropTypes from "prop-types";
import AnchorLink from "react-anchor-link-smooth-scroll";
import GlobalContext from "contexts/global/globalContext";

export default function UtilityNav({ className }) {
  const globalContext = useContext(GlobalContext);
  const handleClick = () => {
    console.log('link close')
    globalContext.isMobile && globalContext.setHamburgerState(false);
  }
  return (
    <ul className={`utility-nav gutter-left gutter-right ${className}`}>
      <li>
        <a
          href="https://endodocuments.com/AVEED/PI"
          target="_blank"
          rel="noopener noreferrer"
        >
          Full Prescribing Information
        </a>
      </li>
      <li>
        <a
          href="https://endodocuments.com/AVEED/MG"
          target="_blank"
          rel="noopener noreferrer"
        >
          Medication Guide
        </a>
      </li>
      <li>
        <a
          href="?scrollto=aveed-isi"
          onClick={handleClick}
          className="btn-expand-isi"
        >
          Important Safety Information
        </a>
      </li>
      <li>
        <a
          href="https://www.aveedrems.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          AVEED<sup>&reg;</sup> Rems Program
        </a>
      </li>
    </ul>
  );
}

UtilityNav.propTypes = {
  className: PropTypes.string,
};
