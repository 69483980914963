import React from 'react';
import { Row, Col } from "react-flexbox-grid";
import ISIWrapper from './ISIWrapper';

export default function ISIPatient() {
  return (
    <ISIWrapper>
      <Row>
        <Col md={6}>
          <section className="isi-content">
            <h2>WHAT IS AVEED<sup>&reg;</sup>?</h2>
            <p>AVEED<sup>&reg;</sup> (testosterone undecanoate) injection is a prescription medicine that contains testosterone, a controlled substance (CIII). AVEED<sup>&reg;</sup> is used to treat adult males who have low or no testosterone due to certain medical conditions.</p>
            <p>AVEED<sup>&reg;</sup> is only for adult males who need testosterone replacement therapy and when the benefit of receiving AVEED<sup>&reg;</sup> is more than the risk of pulmonary oil microembolism (POME) and anaphylaxis.</p>
            <p>It is not known if AVEED<sup>&reg;</sup> is safe or effective to treat men who have low testosterone due to aging.</p>
            <p>It is not known if AVEED<sup>&reg;</sup> is safe and effective for use in children younger than 18 years old. Improper use of AVEED<sup>&reg;</sup> may affect bone growth in children.</p>
          </section>
        </Col>
        <Col md={6}>
          <section className="isi-content">
            <h2>IMPORTANT SAFETY INFORMATION FOR AVEED<sup>&reg;</sup></h2>
            <p><strong>Do not receive AVEED<sup>&reg;</sup> if you:</strong></p>
            <ul className="green">
              <li>
                <p className="pb-0"><strong>have breast cancer, have or might have prostate cancer, are allergic to AVEED<sup>&reg;</sup> or to any of the ingredients in AVEED<sup>&reg;</sup> (testosterone undecanoate, castor oil, benzyl benzoate).</strong></p>
              </li>
              <li>
                <p className="pb-0"><strong>are a woman, or are pregnant.</strong> AVEED<sup>&reg;</sup> may harm your unborn baby.</p>
              </li>
            </ul>
            <p className="para-break"><strong>AVEED<sup>&reg;</sup> may cause serious side effects, including:</strong></p>
            <ul className="green">
              <li>
                <p><strong>A serious lung problem.</strong> AVEED<sup>&reg;</sup> can cause a serious lung problem called POME. POME is caused by tiny droplets of oil that have traveled to the lungs. Symptoms of a POME reaction may include:</p>
                <ul className="sub-dash-bullet">
                  <li>cough or urge to cough</li>
                  <li>difficulty breathing</li>
                  <li>sweating</li>
                  <li>tightening of your throat</li>
                  <li>chest pain</li>
                  <li>dizziness</li>
                  <li>fainting</li>
                </ul>
              </li>
              <li>
                <p><strong>Serious allergic reactions (anaphylaxis).</strong> AVEED<sup>&reg;</sup> can cause a serious allergic reaction right after receiving the injection. Some of these allergic reactions may be life threatening. These reactions can happen after you receive your first dose of AVEED<sup>&reg;</sup> or may happen after receiving more than 1 dose.</p>
                <p><strong>You may need emergency treatment in a hospital</strong>, especially if these symptoms get worse over the 24 hours after your AVEED<sup>&reg;</sup> injection.</p>
                <p><strong>These side effects may happen during or right after each injection. To be sure that you are not having one of these reactions:</strong></p>
                <ul className="sub-dash-bullet">
                  <li><strong>You need to stay in the doctor's office, clinic, or hospital for 30 minutes after having your AVEED<sup>&reg;</sup> injection so that your doctor can watch you for symptoms of POME or a serious allergic reaction.</strong></li>
                  <li><strong>You can only get AVEED<sup>&reg;</sup> at your doctor's office, clinic, or hospital.</strong></li>
                </ul>
              </li>
              <li><strong>Serious side effects relating to the heart and blood vessels and mental status are associated with abuse of testosterone and anabolic androgenic steroids.</strong></li>
            </ul>
            <p className="para-break"><strong>Before receiving AVEED<sup>®</sup>, tell your doctor if you</strong> have breast cancer, have or might have prostate cancer, have urinary problems due to an enlarged prostate, have heart problems, have liver or kidney problems, have problems breathing while you sleep (sleep apnea), or have any other medical conditions.</p>
            <p>Tell your doctor about all the medicines you take, including prescription and over-the-counter medicines, vitamins, and herbal supplements.</p>
            <p>Receiving AVEED<sup>®</sup> with certain other medicines can affect each other. Especially tell your doctor if you take insulin, medicines that decrease blood clotting, or corticosteroids. Ask your doctor or pharmacist for a list of these medicines, if you are unsure.</p>

            <p><strong>AVEED<sup>®</sup> can cause serious side effects, including:</strong></p>
            <ul className="green">
              <li><span><strong>if you already have enlargement of your prostate gland, your signs and symptoms can get worse</strong> while receiving AVEED<sup>®</sup>.</span></li>
              <li><span>changes in certain blood tests</span></li>
              <li><span><strong>possible increased risk of prostate cancer.</strong> Your doctor should check you for prostate cancer or any other prostate problems before you receive and while you are receiving AVEED<sup>®</sup>.</span></li>
              <li><span><strong>in large doses AVEED<sup>®</sup> may lower your sperm count.</strong></span></li>
              <li><span><strong>liver problems.</strong> Symptoms of liver problems may include nausea or vomiting, yellowing of your skin or whites of your eyes, dark urine, or pain on the right side of your stomach area (abdominal pain).</span></li>
              <li><span><strong>swelling of your ankles, feet, or body, with or without heart failure.</strong> This may cause serious problems for people who have heart, kidney, or liver disease.</span></li>
              <li><span><strong>enlarged or painful breasts.</strong></span></li>
              <li><span><strong>have problems breathing while you sleep (sleep apnea).</strong></span></li>
              <li><span><strong>blood clots in the legs or lungs.</strong> This can include pain, swelling or redness of your legs, difficulty breathing, or chest pain.</span></li>
              <li><span><strong>possible increased risk of heart attack, death, or stroke.</strong></span></li>
            </ul>
            <p className="para-break"><strong>Call your doctor right away if you have any of the serious side effects listed above.</strong></p>
            <p><strong>The most common side effects of AVEED<sup>®</sup> include</strong> acne, pain at the injection site, increased prostate specific antigen (a test used to screen for prostate cancer), increased estradiol level, low testosterone level, feeling tired, irritability, increased red blood cell count, difficulty sleeping, or mood swings.</p>
            <p><strong>Other side effects include</strong> more erections than are normal for you or erections that last for a long time.</p>
            <p>Drug abuse is the intentional use of a drug for its rewarding mental and physical effects. Physical dependence is characterized by withdrawal symptoms after abruptly stopping the drug or significantly reducing the dose. Serious side effects relating to the heart and blood vessels, for instance, heart attack and congestive heart failure, and mental status, including major depression, paranoia, delusions, hallucinations, hostility, and aggression, are associated with abuse of testosterone and anabolic androgenic steroids.</p>
            <p><strong>Side effects associated with abuse or misuse by men include</strong> mini-stroke, convulsions, mild mania (elation and hyperactivity), irritability, abnormal blood lipids, decrease in size or function of testicles, decreased or absence of fertility; <strong>by women include</strong> unwanted hair growth, development of male characteristics, voice deepening, clitoris enlargement, decrease in size or function of breasts, male pattern baldness, menstrual irregularities; <strong>by male and female adolescents include</strong> halting of growth and early puberty.</p>
            <p>Tell your doctor if you have any side effect that bothers you or that does not go away.</p>
            <p>These are not all the possible side effects with AVEED<sup>®</sup>. For more information, ask your doctor or pharmacist. You may report side effects to FDA at 1-800-FDA-1088.</p>
            <p><strong>Geriatric Patients:</strong> There have not been sufficient numbers of geriatric patients in controlled clinical studies with AVEED<sup>®</sup> to determine whether efficacy or safety in those over 65 years of age differs from younger subjects. There are insufficient long-term safety data in geriatric patients to assess the potential risks of cardiovascular disease and prostate cancer</p>
            <p><strong>Please see <a href="https://endodocuments.com/AVEED/PI" target="_blank" rel="noopener noreferrer">full Prescribing Information</a>, including Boxed Warning and patient <a href="https://endodocuments.com/AVEED/MG" target="_blank">Medication Guide</a>.</strong></p>

          </section>
        </Col>
      </Row>
    </ISIWrapper>
  )
}
