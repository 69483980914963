import React, { useContext, useEffect } from "react";
import GlobalContext from "contexts/global/globalContext";

export default function IndicationBar({ className }) {
  const globalContext = useContext(GlobalContext);

  return (
    <ul className={`indication-bar ${className}`}>
      {globalContext.currentIndication === "patient" ? (
        <li className="aveed-finder-select">
          <a
            href="/aveed-finder"
            className={
              globalContext.currentPageName === "aveed-finder" ? "active" : ""
            }
          >
            Find AVEED<sup>&reg;</sup> Near You
          </a>
        </li>
      ) : (
        <li className="aveed-signup-select">
          <a
            href="/hcp/aveed-finder-signup"
            className={
              globalContext.currentPageName === "aveed-finder-signup"
                ? "active"
                : ""
            }
          >
            AVEEDfinder Sign-up
          </a>
        </li>
      )}
      <li className="pat-indication-select">
        <a
          href="/"
          className={
            globalContext.currentIndication === "patient" ? "active" : ""
          }
        >
          Patient Home
        </a>
      </li>
      <li className="hcp-indication-select">
        <a
          href="/hcp/"
          className={globalContext.currentIndication === "hcp" ? "active" : ""}
        >
          HCP Home
        </a>
      </li>
    </ul>
  );
}
