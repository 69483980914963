import React, { useContext } from 'react'
import AnchorLink from "react-anchor-link-smooth-scroll";
import GlobalContext from "contexts/global/globalContext";

export default function AnchorBlock({ children, blockId }) {
  const globalContext = useContext(GlobalContext);

  return (
    <AnchorLink offset={globalContext.headerHeight} href={`#${blockId}`} className="aveed-button green">
      {children}
    </AnchorLink>
  )
}
