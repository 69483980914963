import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import NavWrapper from './NavWrapper';

export default function NavPatient() {
  return (
    <NavWrapper>
      <hr className="nav-separator-mobile" />
      <Link to="/what-is-hypogonadism/" activeClassName="active"><span>About Hypogonadism</span></Link>
      <span className="nav-separator-desktop">|</span>
      <hr className="nav-separator-mobile" />
      <Link to="/why-aveed/" activeClassName="active"><span>Why AVEED<sup>&reg;</sup>?</span></Link>
      <span className="nav-separator-desktop">|</span>
      <hr className="nav-separator-mobile" />
      <Link to="/what-to-expect/" activeClassName="active"><span>What to Expect</span></Link>
      <span className="nav-separator-desktop">|</span>
      <hr className="nav-separator-mobile" />
      <Link to="/paying-for-aveed/" activeClassName="active"><span>Paying for AVEED<sup>&reg;</sup></span></Link>
      <span className="nav-separator-desktop">|</span>
      <hr className="nav-separator-mobile" />
      <Link to="/resources/" activeClassName="active"><span>Resources</span></Link>
      <hr className="nav-separator-mobile" />
    </NavWrapper >
  )
}

NavPatient.propTypes = {
  className: PropTypes.string
};