
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import GlobalContext from 'contexts/global/globalContext';

/**
 *
 * pageTitle: String (Required)
 * pageDescription: String (Optional)
 * pageKeywords: Array of Strings (Optional)
 * ##Note## Optional values, if not provided, will be used from app.settings.js
 *
 * Example:
 * import { SEO } from "../codes/components";
 *
 * <SEO pageTitle="home" />
 */

function SEO({ pageTitle, pageDescription, pageKeywords }) {

  const globalContext = useContext(GlobalContext);
  const appSettings = globalContext.appConfigs;
  const metaDescription = pageDescription || appSettings.metaData.siteDefaultDescription;
  const metaKeywords = pageKeywords || appSettings.metaData.siteDefaultKeywords;

  return (
    <Helmet
      title={pageTitle}
      titleTemplate={`%s | ${appSettings.metaData.siteName}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaKeywords.length > 0
            ? {
              name: `keywords`,
              content: metaKeywords.join(`, `),
            }
            : []
        )}
    />
  )
}

SEO.propTypes = {
  pageDescription: PropTypes.string,
  pageKeywords: PropTypes.arrayOf(PropTypes.string),
  pageTitle: PropTypes.string.isRequired,
}

export default SEO
