import React from 'react';
import PropTypes from 'prop-types';
import HeaderWrapper from './HeaderWrapper';
import UtilNav from '../utility-nav';
import { NavHcp } from '../nav';
import Logo from '../logo';

export default function HeaderHcp({ className }) {
  return (
    <HeaderWrapper>
      <header className={className}>
        <div className="desktop-utility-container">
          <div className="dfa-container gutter-left gutter-right">
            <UtilNav />
          </div>
        </div>
        <div className="logo-nav-container dfa-container  gutter-left gutter-right">
          <Logo link="/hcp/" />
          <NavHcp />
        </div>
        <div className="dfa-container  gutter-left gutter-right header-divider">
          <hr />
        </div>
      </header>
    </HeaderWrapper>
  )
}

HeaderHcp.propTypes = {
  className: PropTypes.string
};

