import React from 'react'

export default function index({ children, title, colorWare, className, ...props }) {
  return (
    <div {...props} className={`boxy ${colorWare || 'green'} ${className}`}>
      <div className="title" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="body">{children}</div>
    </div>
  )
}
