import React from 'react';
import imgLogo from 'images/logo.png';

const Logo = ({ link, className }) => (
  <a href={link} className={`logo ${className}`}>
    <img src={imgLogo} alt="Website Logo" />
  </a>

)

export default Logo
