import React, { useContext } from 'react';
import Modal from 'react-awesome-modal';
import GlobalContext from 'contexts/global/globalContext';
/* eslint-disable */
export default function ExternalLinkModal(ExternalURL) {
  const globalContext = useContext(GlobalContext);
  const handleContinue = (e) => {
    e.preventDefault();
    globalContext.setExternalLinkModal(false);
    setTimeout(() => {
      window.open(ExternalURL.ExternalURL);
    }, 100);
  }
  return (
    <Modal visible={globalContext.externalLinkModalState} effect="fadeInUp">
      <div id="modal-external-away" className="modal-container" >

        <h2>You are now leaving the AveedUSA.com website. <span className="text-orange nobr">Endo Pharmaceuticals</span> is not responsible for the content of linked sites.</h2>

        <div className="modal-contents">
          {/*<p>Do you want to continue?</p>*/}
        </div>

        <div className="modal-actions">
          <a href="#" onClick={() => globalContext.setExternalLinkModal(false)} className="aveed-button green">Cancel</a>
          <a href="#" onClick={handleContinue} className="aveed-button green">OK</a>
        </div>
      </div>
    </Modal >
  )
}
