import React, {
  useEffect,
  useLayoutEffect,
  useContext,
  useState,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import GlobalContext from "contexts/global/globalContext";
import { ExternalLinkModal } from "components";
import { HcpLinkModal } from "components";
import { scroller } from "react-scroll";
import { getUrlParameter } from "libs/util.helper";

export default function Body({ children, type, refs, pageName }) {
  const globalContext = useContext(GlobalContext);
  const [topOffset, setTopOffset] = useState(0);
  const [cookies, setCookie] = useCookies(["cookie-name"]);
  const [anchorId, setAnchorID] = useState(null);

  useEffect(() => {
    globalContext.setIndication(type);
    globalContext.setCurrentPageName(pageName);
    //Check for magicshot and then set cookies
    //Check for HCP interstitial cookie
    if (!getUrlParameter("magicshot")) {
      if (type === "hcp" && !cookies.hcpVisited) {
        globalContext.setHcpModal(true);
      }
    }

    refs && globalContext.setReferences(refs);
    //eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    setAnchorID(getUrlParameter("scrollto"));
    setTopOffset(globalContext.headerHeight);
  }, [globalContext.headerHeight]);

  useLayoutEffect(() => {
    setTimeout(() => {
      scrollFunction(anchorId, globalContext.headerHeight);
    }, 100);
  }, [topOffset]);

  const scrollFunction = (el, _topoffset) => {
    scroller.scrollTo(el, {
      duration: 200,
      delay: 0,
      smooth: true,
      offset: -_topoffset,
    });
  };
  return (
    <Fragment>
      <div
        className="body-content-wrapper"
        style={{
          paddingTop:
            globalContext.appConfigs.headerType === "fixed" ? topOffset : 0,
        }}
      >
        {children}
      </div>
      {globalContext.externalLinkURL && (
        <ExternalLinkModal ExternalURL={globalContext.externalLinkURL} />
      )}
      <HcpLinkModal HcpURL={globalContext.hcpURL} />
    </Fragment>
  );
}

Body.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};
