import React, { useContext } from 'react';
import GlobalContext from 'contexts/global/globalContext';
/* eslint-disable */
/**
 *
 * Example:
 * import { ExternalLink } from "../codes/components"
 *
 * <ExternalLink url="http://www.deerfieldagency.com">My Link Name</ExternalLink>
 */
export default function ExternalLink({ id, className, style, url, children }) {
  const globalContext = useContext(GlobalContext);
  const handleClick = (e) => {
    e.preventDefault();
    globalContext.setExternalLinkModal(true);
    globalContext.setExternalLinkURL(url);
  }

  return (
    <a
      id={id}
      className={className}
      style={style}
      href="#"
      onClick={handleClick}
    >
      {children}
    </a>
  )
}
